// Frameworks
import React from "react";

// Utils
import { useState, useContext } from "react";

// Configs
import { CONFIG } from "./helpers/CONFIG";
import FIREBASE_CONFIG from "./helpers/firebaseConfig";

// Pages
import LoadingPage from "./pages/Loading.jsx";

// Styles
import "./App.scss";

// Components
import { Suspense } from "react";

// 3D Scene
// const Scene = React.lazy(() => import('./components/Scene'));

var firebase;

const HomePage = React.lazy(async () => {
  await import("firebase/app").then(async (firebase) => {
    await import("firebase/analytics");
    await import("firebase/database");
    iniFirebase(firebase);
  });
  return import("./pages/Home.jsx");
});

var loadingAnimationEntered = false;

setTimeout(() => (loadingAnimationEntered = true), 1000);

const onPageResize = () => {
  const fontSize = window.innerWidth / 25;
  if (window.innerHeight < 1100) {
    document.documentElement.style.fontSize =
      (fontSize > 10.5 ? 10.5 : fontSize < 10 ? 10 : fontSize) + "px";
  } else {
    document.documentElement.style.fontSize =
      (fontSize > 16 ? 16 : fontSize < 10 ? 10 : fontSize) + "px";
  }
};

onPageResize();
window.addEventListener("resize", onPageResize);

const iniFirebase = (firebase_) => {
  if (!firebase) {
    firebase_.initializeApp(FIREBASE_CONFIG);
    firebase_.analytics();
    firebase = firebase_;
  }
};

function App() {
  const config = useContext(CONFIG);

  const prefix = config.prefix + "main-";

  const [contentLoaded, setContentLoadingState] = useState(false);
  const [context, setContext] = useState(config);

  const onContentLoaded = () => {
    if (contentLoaded === true) return;
    if (loadingAnimationEntered) setContentLoadingState(true);
    else {
      setInterval(() => {
        if (loadingAnimationEntered) setContentLoadingState(true);
      }, 100);
    }
    setContext({ firebase: firebase, prefix: config.prefix });
  };

  return (
    <CONFIG.Provider value={context}>
      <div className={`${prefix}container`}>
        <LoadingPage loaded={contentLoaded} />
        <Suspense className={`${prefix}loading-container`} fallback={<span />}>
          <section className={`${prefix}contents-container`}>
            {/* <Scene loaded={contentLoaded}/> */}
            <HomePage onLoad={onContentLoaded} />
          </section>
        </Suspense>
      </div>
    </CONFIG.Provider>
  );
}

export default App;
