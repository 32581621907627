// TODO: Replace the following with your app's Firebase project configuration
// For Firebase JavaScript SDK v7.20.0 and later, `measurementId` is an optional field
const firebaseConfig = {
  apiKey: "AIzaSyBgjicxEMzeVZR_mdIqgJAoSvVfi8mSwHM",
  authDomain: "shadowhusky-3a973.firebaseapp.com",
  databaseURL: "https://shadowhusky-3a973.firebaseio.com",
  projectId: "shadowhusky-3a973",
  storageBucket: "shadowhusky-3a973.appspot.com",
  messagingSenderId: "750738184475",
  appId: "1:750738184475:web:f0a9ad4cb9bc7882411bbe",
  measurementId: "G-Q902E5DCJ4"
};

export default firebaseConfig;
