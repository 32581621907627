// Styles
import "./LightFrame.css";

// Utils
import classnames from "classnames";

const prefix = "lightframe-";

const LEDLight = (type, borderColor, disappear) => {
  return (
    <div
      className={classnames(
        `${prefix}light`,
        `${prefix}light-${type}`,
        !disappear && `${prefix}light-animated-show`,
        disappear && `${prefix}light-animated-disappear`
      )}
      style={{ borderColor: borderColor, color: borderColor }}
    ></div>
  );
};

function LightFrame(props) {
  const { topLight, rightLight, bottomLight, leftLight, disappear } = props;
  return (
    <div className={`${prefix}container`}>
      {topLight && LEDLight("top", topLight.color, disappear)}
      {rightLight && LEDLight("right", rightLight.color, disappear)}
      {bottomLight && LEDLight("bottom", bottomLight.color, disappear)}
      {leftLight && LEDLight("left", leftLight.color, disappear)}
    </div>
  );
}

export default LightFrame;
