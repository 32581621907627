// Utils
import { useContext } from "react";
import classnames from "classnames";

// Configs
import { CONFIG } from "../helpers/CONFIG";

// Styles
import "./Loading.scss";

// Components
import LightFrame from "../components/LightFrame";
import DynamicBackground from "../components/DynamicBackground";
import Tilt from "react-tilt";

function LoadingPage(props) {
  const config = useContext(CONFIG);
  const prefix = config.prefix + "loading-";
  const { loaded } = props;
  const lightFrameProps1 = {
    color: "#0B6AB0",
  };
  return (
    <div
      className={classnames(
        `${prefix}container`,
        loaded && `${prefix}container-animated-disappear`
      )}
    >
      <LightFrame
        disappear={loaded}
        topLight={lightFrameProps1}
        bottomLight={lightFrameProps1}
      ></LightFrame>
      <Tilt
        className={`${prefix}tilt-container`}
        options={{ max: 30, scale: 1, speed: 600 }}
      >
        <div>
          <DynamicBackground loaded={loaded} />
        </div>
        <p className={classnames(`${prefix}text`)}>Loading...</p>
      </Tilt>
    </div>
  );
}

export default LoadingPage;
